import PromoContentGalleryUI, {
  Props as UIProps,
} from '@interflora/ui-components/build/components/PromoContentGallery/PromoContentGallery'
import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { ADD_DELIVERY_PASS_TO_CART } from '../../graphql/queries'
import { useMutation } from '@apollo/client'
import { SiteContext } from '@interflora/ui-components/build/utils/common'
import AnalyticsContext from 'context/AnalyticsContext'

type Props = UIProps

const PromoContentGallery = (props: Props) => {
  const { getCustomer } = useContext(SiteContext)
  const router = useRouter()
  const analytics = useContext(AnalyticsContext)
  /** add to cart mutation */
  const [addToBasketMutation] = useMutation(ADD_DELIVERY_PASS_TO_CART)

  const addDeliveryPassToCart = async (sku: string) => {
    const response = await addToBasketMutation({ variables: { input: { sku } } })
    const items = response.data.addDeliveryPassToCart?.consignments
    const idpType = sku == 'INTERFLORAPLATINUM' ? 'Platinum' : 'Gold'

    analytics.idpJourney('IDP Page', idpType)
    if (!getCustomer.data?.customer?.id || items?.length > 1) {
      router.push('/basket')
    } else if (getCustomer.data?.customer?.id && items?.length === 1) {
      /** redirecting to checkout contact information page */
      router.push('/checkout/contact')
    } else {
      throw new Error('There was a problem, please try again.')
    }
  }

  return <PromoContentGalleryUI {...props} addDeliveryPassToCart={addDeliveryPassToCart} />
}

export default PromoContentGallery
